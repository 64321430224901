/*:root{*/
/*  --primary-color: #ffdd00;*/
/*  --primary-background: #231f20;*/
/*  --secondary-background: #231f20;*/
/*  --primary-hover-color: #b39b00;*/
/*  --success-color: #80ad5a;*/
/*  --border-color: #CCCCCC;*/
/*  --text-color: #d9d6c6;*/
/*  --primary-danger: #cc1f00;*/
/*  --gray-color: #757575;*/
/*  --darkgray-color: rgba(0, 0, 0, 0.11);*/
/*}*/

:root{
  --primary-color: #ffdd00;
  --primary-background: #fff9de;
  --secondary-background: #111111FF;
  --primary-hover-color: #b39b00;
  --success-color: #80ad5a;
  --border-color: #CCCCCC;
  --text-color: #111111FF;
  --primary-danger: #cc1f00;
  --gray-color: #757575;
  --darkgray-color: rgba(0, 0, 0, 0.11);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--primary-color);

}

a:hover {
  color: var(--primary-hover-color);

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*<editor-fold desc="ANT Design">*/
.ant-card .ant-card-body {
  height: 100%;
}

.ant-layout-content {
  background: var(--primary-background);
  color: var(--text-color);
}

/*<editor-fold desc="Header">*/
.ant-layout-header {
  padding: 0 16px;
}
.ant-layout-header {
  background: var(--secondary-background);
}
/*</editor-fold>*/

/*<editor-fold desc="Menu">*/
.ant-menu-light {
    background: var(--secondary-background);
    color: var(--primary-color);
}
.ant-menu-light .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
  color: var(--primary-hover-color);
  font-weight: bold;
}
.ant-menu-light .ant-menu-item-disabled {
  color: var(--gray-color) !important;
}
.ant-menu-light .ant-menu-item-disabled:hover {
  text-decoration: line-through;
}
/*</editor-fold>*/
/*</editor-fold>*/
