.carrouselPicture {
    max-height: 550px;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    object-position: top;
}

.imageFooter {
    position: absolute;
    bottom: 4px;
    width: 100%;
    height: 100%;
    background-color: var(--secondary-background);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translateY(75%);
    transition: transform 0.3s ease-in-out;
}

.footerCircle {
    position: relative;
    bottom: 30%;
    width: 50px;
    height: 50px;
    min-height: 50px;
    border-radius: 50%;
    background-color: var(--secondary-background);
    color: var(--primary-background);
    z-index: 1;
    transition: opacity 0.3s ease-in-out;
}

.footerText {
    position: relative;
    bottom: 32%;
    color: var(--primary-background);
    transition: opacity 0.3s ease-in-out;
}

.footerDescription {
    position: relative;
    bottom: 10%;
    color: var(--primary-background);
    margin: 0 12px;
    text-align: center;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.imageContainer {
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.imageContainer:hover .imageFooter {
    transform: translateY(2%);
    opacity: 0.8;
}

.imageContainer:hover .footerDescription {
    opacity: 1;
}

.imageContainer:hover .footerText {
    opacity: 0;
}

.imageContainer:hover .footerCircle {
    opacity: 0;
}
